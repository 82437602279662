import * as React from "react"
import Button from '@mui/material/Button'

import * as classes from '../styles/404.module.scss'
import Layout from '../components/Layout/Layout'
import SEO from "../components/Seo"
import headImg from '../images/404.svg'
import { navigate } from "gatsby"

const NotFoundPage = () => {
  const handleAction = ()=> {
    navigate('/')
  }
  return (
    <Layout>
      <SEO title="404" />
      <div className={classes.root}>
        <img width="279.27px" height="289.24px" src={headImg} alt="404" />
        <h1 className={classes.code}>404</h1>
        <h1 className={classes.title}>The requested URL was not found on this server ⚠️</h1>
        <p className={classes.descriptiotn}>The requested URL /error was not found on this server.</p>
        <Button onClick={handleAction} disableElevation className={classes.actionBut} variant="contained">Back to home</Button>
      </div>
    </Layout>
  )
}

export default NotFoundPage
